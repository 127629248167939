import React from "react"
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby'

import HomeContent from "../components/homeContent/index";

function Index({location, lang, data}) {
  let posts = data.allWordpressPost.edges

  posts = posts.map(post => {
    return {
      ...post.node,
      featureImageUrl: `https://wp-api.voalabs.com/wp-content/uploads/${post.node.featured_media.media_details.file}`
    }
  })

  return(
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Voa Labs | Digital Product Studio</title>
        <meta name="description" content="Voa Labs strategizes, designs & builds digital experiences. We are a digital product studio from Switzerland." />
      </Helmet>
      <HomeContent lang={lang} location={location} posts={posts}/>
    </div>
  )
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

// limit output to 3 newest articles
export const query = graphql`
  query HomePageQuery {
    allWordpressPost(limit: 3, sort: {fields: date, order: DESC}) {
      edges {
        node {
          id
          title,
          slug,
          date
          featured_media {
            media_details {
              file
            }
          }
        }
      }
    }
}
`

export default Index;